<template>
    <div class="related-article-box" :class="inCarousel ? 'in-carousel' : ''" :boxContent="boxContent" @click="openContent(boxContent.permalink, boxContent.postType)">
        <img :src="boxContent.imgUrl" alt="">
        <div class="related-title">{{boxContent.article_title}}</div>
        <p class="related-description">{{boxContent.article_subtitle}}</p>
    </div>    
</template>

<script>
import store from '../store/store'
export default {
    props: {
        boxContent: { required: true },
        inCarousel: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        openContent(permalink, postType) {
            
            var isEvent = 0;
            if(postType=='event')   isEvent = 1;
            store.setArticlePermalink(permalink, isEvent);
            if (isEvent == 1)   this.$router.push('/evento/'+permalink);
            else                this.$router.push('/articulo/'+permalink);

        }
    }
}
</script>

<style lang="scss" scoped>
    .related-article-box {
        width: 157px;
        cursor: pointer;
        img {
            width: 100%;
            height: 89px;
            object-fit: cover;
            margin-bottom: 8px;
        }
        .related-title {
            font-size: 0.85rem;
            line-height: 1rem;
            margin: 0 0 5px;
        }
        .related-description {
            line-height: normal;
        }
    }
    .in-carousel {
        margin-right: 10px;
    }
</style>