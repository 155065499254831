<template>
    <div class="receive-cta">
            <subscribe-button />
    </div>    
</template>

<script>
import SubscribeButton from './SubscribeButton.vue'
export default {
  components: { SubscribeButton },
    
}
</script>

<style lang="scss" scoped>
</style>