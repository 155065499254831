<template>
    <div class="receive-cta">
            <h6 class="receive-title">ÚLTIMAS NOTICIAS</h6>
            <subscribe-button />
    </div>    
</template>

<script>
import SubscribeButton from './SubscribeButton.vue'
export default {
  components: { SubscribeButton },
    
}
</script>

<style lang="scss" scoped>
</style>