<template>
    <div class="footer-copyright">
        <p>© 2020 Revista Tempo Todos los Derechos Reservados</p>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .footer-copyright {
        p {
            font-size: 0.70rem !important;
            font-weight: 400;
        }
    }

</style>